import { Card, CardContent, Chip, Typography } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import HelpIcon from '@material-ui/icons/Help'
import WarningIcon from '@material-ui/icons/Warning'
import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'

const pageTitle = "About WorkWithVisa.com"

const AboutPage = () => (
  <Layout>
    <SEO title={pageTitle} description="The mission of WorkWithVisa.com is to find companies that provide Visa sponsorship for talented people."/>
    <Card>
        <CardContent>
        <Chip
            icon={<InfoIcon />}
            label={pageTitle}
            color="primary"
            variant="outlined"
        />
        <div>
        <br />
        The mission of WorkWithVisa.com is to be the go-to place when you would like to work with visa and to find companies that provide visa sponsorship for talented people.
        <br /><br />
        We try to collect all the job offers where the company willing to sponsor the Visa for the candidates.
        There are a lot of people who would like to try their luck, move to another country, start a new life.
        Meanwhile, there are companies who do not know about these people or do not even know about the option to provide Visa sponsorship.
        </div>
        <br /><br />
        <Chip
            icon={<HelpIcon />}
            label="What kind of services do we provide?"
            color="primary"
            variant="outlined"
        />
        <ul>
            <li>
                Post a job directly on WorkWithVisa.com. <a href="mailto:job@workwithvisa.com">        
                <Chip
                    label="Interested?"
                    color="primary"
                    size="small"
                    clickable
                    />
                </a>
            </li>
            <li>Share the job offers on Twitter and Facebook to reach more people.</li>
            <li>
                Suggest relocation services when you are ready to move. Are you a relocation agency? <a href="mailto:relocation@workwithvisa.com">
                <Chip
                    label="Contact us!"
                    color="primary"
                    size="small"
                    clickable
                    />
                </a>
            </li>
            <li>
                All of our services are free in BETA. <WarningIcon/>
            </li>
        </ul>
        </CardContent>
    </Card>
  </Layout>
)

export default AboutPage
